export const WEBSITE_TITLE = 'Dr. med. univ. Birgit Höbel';

export const CDN_URL = 'https://res.cloudinary.com/iteratus/image/upload';
export const CDN_FOLDER = 'birgit-hoebel';
export const IMG_LOCAL_PATH = './images';
export const IMG_SIZES = [210, 420, 600, 768, 840, 1000, 1200, 1536, 1700];

export const IMG_HTML_SIZES = {
  third: '(max-width: 768px) 90vw, (max-width: 1280px) 30vw, 380px',
  half: '(max-width: 768px) 90vw, (max-width: 1280px) 45vw, 560px',
  full: '(max-width: 1280px) 90vw, 1152px'
};
