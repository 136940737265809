import React, { useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import styles from './App.module.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Imprint from './pages/Imprint';
import Error404 from './pages/Error404';
import { WEBSITE_TITLE } from './config';

const ScrollToTop = ({children}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
}

function App() {
  return (
    <>
      <Helmet>
        <title>{WEBSITE_TITLE}</title>
        <meta
          name="description"
          content="Fachärztin für Psychiatrie und psychotherapeutische Medizin"
        />
      </Helmet>

      <BrowserRouter>
        <ScrollToTop>
          <div className={styles.app}>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/impressum" element={<Imprint />} />
              <Route element={<Error404 />} />
            </Routes>
            <Footer />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
