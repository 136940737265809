import React from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import Address from '../../components/Address';
import Contact from '../../components/Contact';
import Law from '../../components/Law';
import Image from '../../components/Image';
import { IMG_HTML_SIZES, WEBSITE_TITLE } from '../../config';
import styles from '../default.module.scss';

function Imprint() {
  return (
    <>
      <Helmet>
        <title>Impressum - {WEBSITE_TITLE}</title>
      </Helmet>

      <div className={styles.section}>
        <h2>Informationen gemäß §5 E-Commerce-Gesetz und §25 Mediengesetz.</h2>
        <h3>Autor, Inhaber, Verantwortlich</h3>
        <div className={classNames(styles.columns, styles.equal)}>
          <div className={styles.image}>
            <Image src="190206_ordi_82_rgv7d8" sizes={IMG_HTML_SIZES.half} />
          </div>
          <div className={styles.content}>
            <Address short />
            <Contact short />
            <Law />
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <h2>Inhalt</h2>
        <p>
          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
          Vollständigkeit oder Qualität der bereitgestellten Informationen.
          Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
          oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
          der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
          unvollständiger Informationen verursacht wurden, sind grundsätzlich
          ausgeschlossen, sofern seitens des Autors kein nachweislich
          vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
        </p>
        <p>
          Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es
          sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
      </div>

      <div className={styles.section}>
        <h2>Verweise und Links</h2>
        <p>
          Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der
          Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten
          erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die
          Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat
          der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit
          ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten,
          die nach der Linksetzung verändert wurden. Diese Feststellung gilt für
          alle innerhalb des eigenen Internetangebotes gesetzten Links und
          Verweise sowie für Fremdeinträge in vom Autor eingerichteten
          Gästebüchern, Diskussionsforen, Mailinglisten und ähnlichen die
          Meinung Dritter veröffentlichender Einrichtungen/Angeboten. Für
          illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für
          Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
          Informationen entstehen, haftet allein der Anbieter der Seite, auf
          welche verwiesen wurde, nicht derjenige, der über Links auf die
          jeweilige Veröffentlichung lediglich verweist.
        </p>
      </div>

      <div className={styles.section}>
        <h2>Urheber- und Kennzeichenrecht</h2>
        <p>
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
          verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
          beachten, von ihm selbst erstellte Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
          Tondokumente, Videosequenzen und Texte zurückzugreifen.
        </p>
        <p>
          Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte
          geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
          Bestimmungen des jeweils gültigen Kennzeichenrechts und den
          Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund
          der bloßen Nennung ist nicht der Schluß zu ziehen, dass Markenzeichen
          nicht durch Rechte Dritter geschützt sind!
        </p>
        <p>
          Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte
          bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder
          Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in
          anderen elektronischen oder gedruckten Publikationen ist ohne
          ausdrückliche Zustimmung des Autors nicht gestattet.
        </p>
        <p>
          Einige Icons wurden erstellt von{' '}
          <a
            href="https://www.flaticon.com/de/autoren/freepik"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Freepik
          </a>{' '}
          from{' '}
          <a
            href="https://www.flaticon.com/de/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            www.flaticon.com
          </a>
        </p>
      </div>

      <div className={styles.section}>
        <h2>Rechtswirksamkeit dieses Haftungsausschlusses</h2>
        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
          betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
          oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
          nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
          die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
          davon unberührt.
        </p>
      </div>
    </>
  );
}

export default Imprint;
