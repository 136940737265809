import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as Icon404 } from '../../svgs/icon_404.svg';
import { WEBSITE_TITLE } from '../../config';
import styles from '../default.module.scss';
import stylesLocal from './Error404.module.scss';

function Error404() {
  return (
    <>
      <Helmet>
        <title>404: Seite nicht gefunden - {WEBSITE_TITLE}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className={styles.section}>
        <Icon404 className={stylesLocal.icon} />
        <h2>Seite nicht gefunden</h2>
        <div className={classNames(styles.centered)}>
          <p>
            Es kann immer wieder mal passieren, dass einzelne Seiten im Internet
            verschoben oder gelöscht werden. Man hört zwar immer wieder mal,
            dass das Internet nie vergisst, aber das kann dennoch ab und an
            passieren, auch wenn man es kaum glaubt. Dass diese vermeintlich
            auffindbare Seite nun eben zu den genannten verschwundenen Seiten
            gehört ist dennoch eine prinzipiell sehr gute Sache, denn es zeigt,
            dass das Internet lebt und sich permanent verändert.
          </p>
          <p>
            Nichts desto trotz ist dieser Text hier zwar schön, wird aber eher
            nicht den Wunsch befriedigen, die von Ihnen gewünschten Inhalte zu
            finden. Daher schickt es sich, Ihnen eine alternative Möglichkeit
            der Navigation zu bieten:
            <br />
            <Link className={styles.link} to="/">
              Zur Startseite
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default Error404;
