import React from 'react';
import styles from './Law.module.scss';
import { ReactComponent as IconSection } from '../../svgs/icon_section_sign.svg';

function Law() {
  return (
    <div className={styles.component}>
      <IconSection className={styles.icon} />
      <div className={styles.content}>
        <div>Gerichtsstand: Landesgericht Klagenfurt</div>
        <a
          href="https://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=be5fa6ae-ec46-4c50-bff4-3e5ec5a833a4&amp;StandortID=6&amp;StandortName=K%c3%a4rnten+(Bundesland)&amp;Suchbegriff=Birgit+H%c3%b6bel&amp;Page=1"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          WKO-Eintrag
        </a>
      </div>
    </div>
  );
}

export default Law;
