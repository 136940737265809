import React from 'react';
import styles from './OpeningHours.module.scss';
import { ReactComponent as IconClock } from '../../svgs/icon_clock.svg';

function OpeningHours() {
  return (
    <div className={styles.component}>
      <IconClock className={styles.icon} />
      <ul className={styles.times}>
        <li>Mo 8 - 14 Uhr</li>
        <li>Di 8 - 14 Uhr</li>
        <li>Mi 15 - 18 Uhr</li>
        <li>Do 9 - 12 Uhr</li>
        <li>Fr 8 - 12 Uhr</li>
      </ul>
    </div>
  );
}

export default OpeningHours;
