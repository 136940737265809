import React from 'react';
import classNames from 'classnames';
import Address from '../../components/Address';
import Contact from '../../components/Contact';
import Image from '../../components/Image';
import ImageSlider from '../../components/ImageSlider';
import OpeningHours from '../../components/OpeningHours';
import { IMG_HTML_SIZES } from '../../config';
import styles from '../default.module.scss';
import stylesLocal from './Home.module.scss';

const cdnManipulations = ['ar_1.5', 'c_fill'];

const slideshowImages = [
  '190206_ordi_163_xii4ee',
  '190206_ordi_195_s5i5nq',
  '190206_ordi_37_o2mw3g',
  '190206_ordi_49_cszuic',
  '190206_ordi_30_e95kmv',
  '190206_ordi_240_auracq',
  '190206_ordi_82_rgv7d8',
  '190206_ordi_91_b1pd0p',
  '190206_ordi_99_akc7mv'
].map(img => {
  const data = Image.createCDNSizes(img, cdnManipulations);

  return {
    ...data,
    sizes: IMG_HTML_SIZES.full
  };
});

function Home() {
  return (
    <>
      <ImageSlider className={styles.section} images={slideshowImages} />
      <div className={classNames(styles.columns, stylesLocal.info)}>
        <Address />
        <OpeningHours />
        <Contact />
      </div>

      <div className={styles.section}>
        <h2>Terminvereinbarung erbeten</h2>
        <div className={classNames(styles.columns, styles.equal)}>
          <div className={styles.image}>
            <Image src="190209_ordi_80_irhrl5" sizes={IMG_HTML_SIZES.third} />
          </div>
          <div
            className={classNames(
              styles.content,
              styles.centered,
              styles.appointments
            )}
          >
            <p>
              Kontaktieren Sie mich für einen Termin, gerne helfe ich Ihnen
              weiter!
            </p>
          </div>
          <div className={styles.image}>
            <Image src="190209_ordi_177_k9ha99" sizes={IMG_HTML_SIZES.third} />
          </div>
        </div>
      </div>

      <article className={styles.section}>
        <h2>Wann sollte ich zum Psychiater?</h2>
        <div className={classNames(styles.columns, styles.equal)}>
          <div className={styles.image}>
            <Image src="190206_ordi_82_rgv7d8" sizes={IMG_HTML_SIZES.half} />
          </div>
          <div className={styles.content}>
            <ul>
              <li>Bei Verzweiflung und Not</li>
              <li>Bei Hilf- und Ausweglosigkeit</li>
              <li>Bei Anspannung und Angst</li>
              <li>Bei Leere und Bedrohung</li>
              <li>Bei dem Gefühl, eine Sache wächst einem über den Kopf</li>
              <li>
                Bei körperlichen Beschwerden, deren Ursache nicht festgestellt
                werden kann
              </li>
              <li>Bei Erschöpfungsdepression</li>
              <li>Bei Überforderung durch das Leben</li>
              <li>
                Bei chronischen Krankheiten, die auf keine Behandlung ansprechen
              </li>
              <li>
                Bei Lebenskrisen wie Scheidung, Trennung, Jobverlust, Krankheit,
                Tod eines nahestehenden Menschen
              </li>
              <li>
                Wenn Lebensentwürfe, Ziele und Werte in Frage gestellt werden,
                zerbrechen oder nicht mehr erreichbar sind
              </li>
              <li>Wenn Alkohol oder Drogen das Leben bestimmen</li>
              <li>
                Wenn Gedanken nicht mehr steuerbar oder kontrollierbar sind
              </li>
              <li>Wenn das Gedächtnis lückenhaft wird</li>
            </ul>
          </div>
        </div>
      </article>

      <article className={styles.section}>
        <h2>
          Unsere Therapieempfehlung wird individuell erarbeitet und auf Sie
          zugeschnitten.
        </h2>
        <div
          className={classNames(styles.columns, styles.equal, styles.reverse)}
        >
          <div className={styles.image}>
            <Image src="190209_ordi_14_zurni0" sizes={IMG_HTML_SIZES.half} />
            <Image src="190206_ordi_99_akc7mv" sizes={IMG_HTML_SIZES.half} />
          </div>
          <div className={styles.content}>
            <p>
              Wir betreuen Sie in allen seelischen Problemlagen. Deshalb sind
              wir bemüht, Ihnen einen zeitnahen Termin zu ermöglichen.
            </p>
            <p>
              In der Ordination wird durch genaue Aufnahme Ihrer gesamten
              Verfassung und Beschwerden versucht, Ihre Problemlage zu
              verstehen. Um auch Ihre Vorgeschichte miteinbeziehen zu können,
              sind im Vorfeld erfasste Befunde und therapeutische Erfahrungen
              wertvoll. Dadurch wird es möglich, eine Diagnose zu erstellen.
              Anhand der Diagnose wird ein Therapieplan entworfen. Dieser kann
              bestehen aus: Empfehlungen zur Verhaltens- oder Lebensveränderung,
              psychotherapeutischer Maßnahmen, pflanzliche Behandlung,
              medikamentöser Behandlung, Einleitung stationärer oder
              rehabilitativer Maßnahmen und Vermittlung von speziellen
              Beratungseinrichtungen.
            </p>
            <p>
              Grundsätzlich sollte eine Behandlung einer seelischen Störung
              kontinuierlich erfolgen. Deshalb wird in der Praxis auf eine
              längerfristige Betreuung besonders Wert gelegt. Diese Betreuung
              dient zur Begleitung und Kontrolle der eingeleiteten Maßnahmen.
              Durch die regelmäßigen Kontakte kann auch ein gegenseitiges
              Vertrauensverhältnis entstehen, das eine Gesundung aus
              wissenschaftlicher Sicht am besten unterstützt.
            </p>
            <p>
              Durch meine Ausbildung ist es mir möglich, alle Altersgruppen ab
              18 Jahren zu betreuen.
            </p>
          </div>
        </div>
      </article>

      <article className={styles.section}>
        <h2>Über uns</h2>
        <h3>Dr. med. univ. Birgit Höbel</h3>
        <div className={classNames(styles.columns, styles.equal)}>
          <div className={styles.image}>
            <Image
              src="190622_birgit_79_th01ku"
              sizes={IMG_HTML_SIZES.half}
              alt="Dr. med. univ. Birgit Höbel"
            />
          </div>
          <div className={styles.content}>
            <p>geboren 1977 in Klagenfurt</p>
            <p>
              Studium der Humanmedizin Universität Wien
              <br />
              Ausbildung zum Arzt für Allgemeinmedizin im LKH Villach
              <br />
              Ausbildung zum Notarzt
              <br />
              Ausbildung zum Facharzt für Psychiatrie und psychotherapeutische
              Medizin im Klinikum Klagenfurt
            </p>
            <p>
              2011-2017 Oberarzt für Psychiatrie und psychotherapeutische
              Medizin im Klinikum Klagenfurt, Leitung des Subakut Bereiches.
              <br />
              Behandlungsschwerpunkt: Psychosen, Erkrankungen aus dem
              schizophrenen Formenkreis, Bipolar Affektive Erkrankungen,
              Persönlichkeitsstörung, Angsterkrankungen, Zwangserkrankungen,
              Transgender, Krisenintervention.
            </p>
            <p>
              2018 Sonderkrankenhaus De la Tour Treffen, Behandlung von
              Suchterkrankungen.
            </p>
            <p>
              Ausbildung analytische Psychotherapie nach Kernberg (TFP) an der
              Universitätsklinik für Tiefenpsychologie und Psychotherapie Wien.
              <br />
              Schwerpunkt Behandlung von Persönlichkeitsstörungen.
            </p>
            <p>Seit 2019 Psychiatrische Ordination in Klagenfurt.</p>
            <p>2020 Spezialisierung fachspezifische psychosomatische Medizin</p>
          </div>
        </div>
        <h3>Diana Reberšak</h3>
        <div
          className={classNames(styles.columns, styles.equal, styles.reverse)}
        >
          <div className={styles.image}>
            <Image
              src="200115_diana_92-2_fpnzyj"
              sizes={IMG_HTML_SIZES.half}
              alt="Diana Reberšak"
            />
          </div>
          <div className={styles.content}>
            <p>Ordinationsassistentin</p>
            <p>
              Ausbildung in Deutschland
              <br />
              16 Jahre Ordinationsassistentin in verschiedenen Fachgebieten in
              Berlin
              <br />
              2 Jahre Operationsassistentin Neurochirugie in Berlin
              <br />
              Seit 2017 wohnhaft in Kärnten
            </p>
          </div>
        </div>
      </article>
    </>
  );
}

export default Home;
