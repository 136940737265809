import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import classNames from 'classnames';
import styles from './ImageSlider.module.scss';

function ImageSlider(props) {
  const { className, images } = props;
  const finalImages = images.map(img => {
    img.bulletClass = styles.bullet; // eslint-disable-line no-param-reassign
    return img;
  });

  return (
    <ImageGallery
      // lazyLoad
      slideInterval={5000}
      autoPlay
      showBullets
      showFullscreenButton={false}
      showPlayButton={false}
      showNav={false}
      showThumbnails={false}
      additionalClass={classNames(className, styles.component)}
      items={finalImages}
    />
  );
}

ImageSlider.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageSet: PropTypes.arrayOf(
        PropTypes.shape({
          media: PropTypes.string,
          sizes: PropTypes.string,
          srcSet: PropTypes.string
        })
      ),
      original: PropTypes.string
    })
  ).isRequired
};

ImageSlider.defaultProps = {
  className: null
};

export default ImageSlider;
