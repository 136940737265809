import React from 'react';
import PropTypes from 'prop-types';
import styles from './Logo.module.scss';
import { ReactComponent as IconLogo } from '../../svgs/logo_with_name.svg';

function Logo(props) {
  const { useH1 } = props;
  const UsedTag = useH1 ? 'h1' : 'div';

  return (
    <div className={styles.component}>
      <IconLogo className={styles.logo} />
      <UsedTag className={styles.desc}>
        Fachärztin für Psychiatrie und psychotherapeutische Medizin
      </UsedTag>
      <div className={styles.legal}>Alle Kassen und privat</div>
    </div>
  );
}

Logo.propTypes = {
  useH1: PropTypes.bool
};

Logo.defaultProps = {
  useH1: false
};

export default Logo;
