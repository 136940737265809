import React from 'react';
import PropTypes from 'prop-types';
import styles from './Address.module.scss';
import { ReactComponent as IconAddress } from '../../svgs/icon_address.svg';

function Address(props) {
  const { short } = props;

  return (
    <div className={styles.component}>
      <IconAddress className={styles.icon} />
      <div className={styles.content}>
        <a
          href="https://goo.gl/maps/5nd269v6arB2"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {short ? 'Dr. med. univ. Birgit Höbel' : null}
          {short ? <br /> : null}
          {short ? null : '3. Stock'}
          {short ? null : <br />}
          {short ? 'Waagplatz 7/17' : 'Geyerschütt / Waagplatz 7, Top 17'}
          <br />
          9020 Klagenfurt
        </a>
        {short ? null : <br />}
        {short ? null : 'Barrierefrei zu erreichen'}
      </div>
    </div>
  );
}

Address.propTypes = {
  short: PropTypes.bool
};

Address.defaultProps = {
  short: false
};

export default Address;
