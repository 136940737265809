import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import Address from '../Address';
import OpeningHours from '../OpeningHours';
import Contact from '../Contact';
import Logo from '../Logo';

const menuItems = [
  {
    to: '/',
    name: 'Home'
  },
  {
    to: '/impressum',
    name: 'Impressum'
  },
  {
    href: 'https://www.gerhildanderwald.at',
    name: 'Fotos by Gerhild Anderwald'
  },
  { name: `©${new Date().getFullYear()} by iteratus` }
];

const renderMenuItem = item => {
  if (item.to) {
    return (
      <li key={item.to} className={styles.item}>
        <Link className={styles.link} to={item.to}>
          {item.name}
        </Link>
      </li>
    );
  }
  if (item.href) {
    return (
      <li key={item.href} className={styles.item}>
        <a
          className={styles.link}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.name}
        </a>
      </li>
    );
  }

  return (
    <li key={item.name} className={styles.item}>
      {item.name}
    </li>
  );
};

function Footer() {
  return (
    <footer className={styles.footer}>
      <Logo />

      <div className={styles.info}>
        <Address />
        <OpeningHours />
        <Contact />
      </div>

      <nav>
        <ul className={styles.navigation}>{menuItems.map(renderMenuItem)}</ul>
      </nav>
    </footer>
  );
}

export default Footer;
