import React from 'react';
import PropTypes from 'prop-types';
import styles from './Contact.module.scss';
import { ReactComponent as IconTelephone } from '../../svgs/icon_telephone.svg';

function Contact(props) {
  const { short } = props;

  return (
    <div className={styles.component}>
      <IconTelephone className={styles.icon} />
      <div className={styles.content}>
        <a href="tel:+43463504430" className={styles.link}>
          0463 / 50 44 30
        </a>
        {short ? null : <div>Ich freue mich über Ihren Anruf</div>}
      </div>
    </div>
  );
}

Contact.propTypes = {
  short: PropTypes.bool
};

Contact.defaultProps = {
  short: false
};

export default Contact;
